import styleMainScreen from './MainScreen.module.css';
import Aside from './AsideComponents/Aside';
import Main from './MainComponents/Main';
import Orders from '../OrdersComponents/Orders';
import OrdersDelivery from '../OrdersComponents/OrdersDelivery';
import OrdersBalcao from '../OrdersComponents/OrdersDelivery';
import OrdersComanda from '../OrdersComponents/OrdersComanda';
// import { Link } from 'react-router-dom';

function MainScreen () {
    return (
        <>
            {/* <div className={styleMainScreen.screenOrders}> */}
                {/* <Orders/> */}
                {/* <OrdersDelivery/> */}
                {/* <OrdersBalcao/> */}
                {/* <OrdersComanda/> */}
            {/* </div> */}
            <div className={styleMainScreen.mainscreen}>
            <Aside />
            <Main />
            </div>
        </>
    )
}

export default MainScreen;
        // <div className={styleMainScreen.mainscreen}>
            {/* <Aside /> */}
            {/* <Main /> */}
        // </div>