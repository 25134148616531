import '../../App';
import { React, useState } from "react" ;
import {
    Collapse,
    Navbar,
    NavbarToggler,
    Nav,
    // NavItem,
    // NavLink,
    UncontrolledDropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    // NavbarText
} from 'reactstrap';
import styleNavbar from './NavbarMenu.module.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faBoxArchive, faPaperPlane, faTv, faUtensils, faFolder, faInbox } from '@fortawesome/free-solid-svg-icons';

function NavbarMenu() {

	const [isOpen, setIsOpen] = useState(false);
    const toggle = () => setIsOpen(!isOpen);

	return (
		<>
			
            <div className={ styleNavbar.colorNav }>
                <Navbar dark expand="md" >
                    <div className='container'>
                        <NavbarToggler onClick={toggle} />
                        <Collapse isOpen={isOpen} navbar>
                            <Nav className="mr-auto" navbar>
                                <UncontrolledDropdown nav inNavbar>
                                    <DropdownToggle nav caret>
                                        Vendas
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <DropdownItem>
                                            <FontAwesomeIcon icon={faBars} /> &nbsp;
                                            Pedidos
                                        </DropdownItem>
                                        <DropdownItem>
                                            <FontAwesomeIcon icon={faPaperPlane} /> &nbsp;
                                            Pedidos Entrega
                                        </DropdownItem>
                                        <DropdownItem>
                                            <FontAwesomeIcon icon={faBoxArchive} /> &nbsp;
                                            Pedidos Balcão
                                        </DropdownItem>
                                        {/* <DropdownItem divider /> */} {/* Linha de separação */}
                                        <DropdownItem>
                                            <FontAwesomeIcon icon={faUtensils} /> &nbsp;
                                            Comandas / Mesa
                                        </DropdownItem>
                                        <DropdownItem>
                                            <FontAwesomeIcon icon={faFolder} /> &nbsp;
                                            Pedidos Finalizados
                                        </DropdownItem>
                                        <DropdownItem>
                                            <FontAwesomeIcon icon={faInbox} /> &nbsp;
                                            Caixa
                                        </DropdownItem>
                                        <DropdownItem>
                                            <FontAwesomeIcon icon={faTv} /> &nbsp;
                                            Pedidos na TV
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>

                            </Nav>
                            {/* <NavbarText>Simple Text</NavbarText> */}
                        </Collapse>
                    </div>
                </Navbar>
            </div>

		</>
	);
}

export default NavbarMenu;
