import axios from "axios";
import { useEffect, useState } from "react";
import { 
    Button, 
    Modal, 
    ModalHeader, 
    ModalBody, 
    ModalFooter, 
    Table, 
    Form, 
    FormGroup, 
    Label, 
    Input, 
    FormText,
    Col,
    InputGroup, 
    InputGroupText,
    Card,
    CardBody, 
    UncontrolledCollapse
} from "reactstrap";
import { headersAxios, formatNumber, formatDate } from '../../../../assets/scripts/functions';
import stylePayment from './ModalPayment.module.css';

export default function ModalPayment (props) {
    
    const id_pedido = 1475; //Tem que vir na requisição
	const [dataTableItemsPayment, setDataTableItemsPayment] = useState([]);
	const [dataTableItems, setDataTableItems] = useState([]);
	
    const getDataPayment = async () => {
		try {
			const response = await axios.get(`https://api.sigedelivery.com.br/orders/${id_pedido}/payments?with=items%2Ccoupons%2Cdelivery_fees%2Ctips`, {
				headers: headersAxios()
			});
			const data = response.data.data;
			setDataTableItemsPayment(data);
		} catch (err) {
			console.log(err)
		}
	}
	
    useEffect(() => {
		getDataPayment();
		getDataItems();
	},[]);
    
    const getDataItems = async () => {
		try {
			const response = await axios.get(`https://api.sigedelivery.com.br/orders/${id_pedido}/items?with=catalog_item%2Coptions`, {
				headers: headersAxios()
			});
			const data = response.data.data;
			setDataTableItems(data);
		} catch (err) {
			console.log(err)
		}
	}
	
    return (
        <>
            <Modal isOpen={props.modal} toggle={props.toggle} {...props} style={{ minWidth: '90%' }}>
                <ModalHeader toggle={props.toggle}><i className="bi bi-currency-dollar"></i>Pagamento</ModalHeader>
                    <ModalBody>

                        <div>                            
                            <Table bordered className={stylePayment.tableItems}>
                                <thead>
                                    <tr>
                                        <th><Input type="checkbox"/></th>
                                        <th>#</th>
                                        <th>Item</th>
                                        <th>Quantidade</th>
                                        <th>Valor</th>
                                        <th>Opção</th>
                                        <th>Pago</th>
                                        <th>Total</th>
                                        <th>Valor a pagar</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        dataTableItems.length === 0 ? (<tr>Carregando...</tr>) : (
                                            dataTableItems.map((data) => (
                                                data.id_status < 5 ? 
                                                <tr>
                                                    <th><Input type="checkbox"/></th>
                                                    <th scope="row">{data.id}</th>
                                                    <td> { data.catalog_item.nome } </td>
                                                    <td> { (data.quantidade) ? parseFloat(data.quantidade) : 0 } </td>
                                                    <td> { (data.valor) ? formatNumber(data.valor) : formatNumber(0) } </td>
                                                    <td>
                                                        { 
                                                            (data.options) ? data.options.map((v) => (formatNumber(v.valor_total))) : formatNumber(0)
                                                        }
                                                    </td>
                                                    <td></td>
                                                    <td>{ data.valor_total }</td>
                                                    <td>
                                                        <Input type="text" bsSize="sm" className="col-md-2"></Input>
                                                    </td>
                                                </tr>
                                                : ''
                                            ))
                                        )
                                    }
                                </tbody>
                            </Table>                        
                        </div>

                        <hr />

                        <div className={stylePayment.allValues}>
                            <div className={stylePayment.values}>
                                <h5>Valor Total</h5>
                                <h5 className={stylePayment.valor_total} id='valor_total'>R$ 0,00</h5>
                            </div>
                            <div className={stylePayment.values}>
                                <h5>Valor a pagar</h5>
                                <h5 className={stylePayment.valor_pagar} id='valor_pagar'>R$ 0,00</h5>
                            </div>
                            <div className={stylePayment.values}>
                                <h5>Troco</h5>
                                <h5 className={stylePayment.troco} id='troco'>R$ 0,00</h5>
                            </div>
                        </div>

                        <hr />

                        <Button
                            color="secondary"
                            id="toggler"
                            style={{
                                marginBottom: '1rem'
                            }}
                        >
                            Editar &nbsp;
                            <i className="bi bi-chevron-down"></i>
                        </Button>
                                                
                        <UncontrolledCollapse toggler="#toggler">
                            <Card>
                                <CardBody>

                                    <div className={stylePayment.formInputs}>

                                        <Form>
                                            <div className="col-md-12">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <div className="row">
                                                            <FormGroup row>
                                                                <Label sm={4}>Valor total</Label>
                                                                <Col sm={8}>
                                                                <InputGroup>
                                                                    <Input disabled name="valor_total" placeholder="" ></Input>
                                                                    <InputGroupText>
                                                                        R$
                                                                    </InputGroupText>
                                                                </InputGroup>
                                                                </Col>
                                                            </FormGroup>
                                                        </div>
                                                        <div className="row">
                                                            <FormGroup row>
                                                                <Label sm={4}>Adicionar taxa</Label>
                                                                <Col sm={8}>
                                                                    <InputGroup>
                                                                        <Input name="valor_total" placeholder="" ></Input>
                                                                        <InputGroupText>
                                                                            R$
                                                                        </InputGroupText>
                                                                    </InputGroup>
                                                                </Col>
                                                            </FormGroup>
                                                        </div>
                                                        <div className="row">
                                                            <FormGroup row>
                                                                <Label sm={4}>Gorjeta</Label>
                                                                <Col sm={8}>
                                                                    <InputGroup>
                                                                        <Input name="valor_total" placeholder="" ></Input>
                                                                        <InputGroupText>
                                                                            R$
                                                                        </InputGroupText>
                                                                    </InputGroup>
                                                                </Col>
                                                            </FormGroup>
                                                        </div>
                                                        <div className="row">
                                                            <FormGroup row>
                                                                <Label sm={4}>Valor a cobrar</Label>
                                                                <Col sm={8}>
                                                                    <InputGroup>
                                                                        <Input disabled name="valor_total" placeholder=""></Input>
                                                                        <InputGroupText>
                                                                            R$
                                                                        </InputGroupText>
                                                                    </InputGroup>
                                                                </Col>
                                                            </FormGroup>
                                                        </div>
                                                        <div className="row">
                                                            <FormGroup row>
                                                                <Label sm={4}>Valor a pagar</Label>
                                                                <Col sm={8}>
                                                                    <InputGroup>
                                                                        <Input disabled name="valor_total" placeholder="" ></Input>
                                                                        <InputGroupText>
                                                                            R$
                                                                        </InputGroupText>
                                                                    </InputGroup>
                                                                    <FormText  color="muted">
                                                                        Obs.: Utilize este campo se a forma de pagamento for DINHEIRO para calcular o troco.
                                                                    </FormText>   
                                                                </Col>
                                                            </FormGroup>
                                                        </div>
                                                        
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="row">
                                                            <FormGroup row>
                                                                <Label sm={4}>Pagamento</Label>
                                                                <Col sm={8}>
                                                                    <Input type="select" name="id_local" placeholder="Selecione o atendente">
                                                                        <option>
                                                                            1
                                                                        </option>
                                                                        <option>
                                                                            2
                                                                        </option>
                                                                    </Input>
                                                                </Col>
                                                            </FormGroup>
                                                        </div>
                                                        <div className="row">
                                                            <FormGroup row>
                                                                <Label sm={4}>Valor de Desconto</Label>
                                                                <Col sm={8}>
                                                                    <InputGroup>
                                                                        <Input name="valor_total" placeholder="" ></Input>
                                                                        <InputGroupText>
                                                                            R$
                                                                        </InputGroupText>
                                                                    </InputGroup>
                                                                </Col>
                                                            </FormGroup>
                                                        </div>
                                                        <div className="row">
                                                            <FormGroup row>
                                                                <Label sm={4}>Desconto %</Label>
                                                                <Col sm={8}>
                                                                    <InputGroup>
                                                                        <Input name="valor_total" placeholder="" ></Input>
                                                                        <InputGroupText>
                                                                            %
                                                                        </InputGroupText>
                                                                    </InputGroup>
                                                                </Col>
                                                            </FormGroup>
                                                        </div>
                                                        
                                                        <div className="row">
                                                            <FormGroup row>
                                                                <Label sm={4}>Observação</Label>
                                                                <Col sm={8}>
                                                                    <InputGroup>
                                                                        <Input name="valor_total" placeholder="" ></Input>
                                                                    </InputGroup>
                                                                </Col>
                                                            </FormGroup>
                                                        </div>
                                                        
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>


                                    </div>
                                </CardBody>
                            </Card>
                        </UncontrolledCollapse>

                        {/* Table pagamento*/}
                        <div className={stylePayment.payments}>

                            <Table className={stylePayment.tablePayments}>
                                <thead>
                                    <tr>
                                        <th>#</th>
                                        <th>Pagamento</th>
                                        <th>Valor</th>
                                        <th>Taxa</th>
                                        <th>Gorjeta</th>
                                        <th>Desconto</th>
                                        <th>Total</th>
                                        <th>Valor pago</th>
                                        <th>Troco</th>
                                        <th>Data</th>
                                        <th>Observação</th>
                                        <th>Ações</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        dataTableItemsPayment.length === 0 ? (<tr>Carregando...</tr>) : (
                                            dataTableItemsPayment.map((data) => (
                                                <tr>
                                                    <th scope="row">{data.id}</th>
                                                    <th></th>
                                                    <td>{formatNumber(data.valor_pagar)}</td>
                                                    <td>{formatNumber(data.valor_taxa)}</td>
                                                    <td>
                                                        {
                                                            (data.tips) ? data.tips.map((res) => formatNumber(res.valor_garcom_pago) ) : ''
                                                        }
                                                    </td>
                                                    <td>{formatNumber(data.valor_desconto)}</td>
                                                    <td>{formatNumber(data.valor_pago)}</td>
                                                    <td>{formatNumber(data.valor_pago)}</td>
                                                    <td>{formatNumber(data.troco_dinheiro)}</td>
                                                    <td>{formatDate(data.data_pagamento)}</td>
                                                    <td>{data.observacao}</td>
                                                    <td><Button className='btn btn-danger' id={data.id}><i className="bi bi-x"></i></Button></td>
                                                </tr>
                                            ))
                                        )
                                    }
                                </tbody>
                            </Table>

                        </div>


                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={props.toggle}>
                        <i className="bi bi-currency-dollar"></i>Realizar pagamento
                        </Button>{' '}
                        <Button color="secondary" onClick={props.toggle}>
                            Sair
                        </Button>
                    </ModalFooter>
            </Modal>
        </>
    );
}