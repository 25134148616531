//Função que formata valor
export function formatNumber(number) {
    return number.toLocaleString('pt-br', {style: 'currency', currency: 'BRL'});
}

//Função que formata data
export function formatDate(date) {
    return new Date(date).toLocaleDateString('pt-BR');
}

export function colorStatus (id_status) {
    if(id_status === 1)
        return 'primary';
    else if(id_status === 2)
        return 'orange';
    else if(id_status === 3)
        return 'purple';
    else if(id_status === 4)
        return 'secondary';
    else if(id_status === 5)
        return 'success';
    else if(id_status === 6)
        return 'cyan';
    else if(id_status === 7)
        return 'danger';
    else if(id_status === 8) 
        return 'dark';
}

export function headersAxios() {
    return {
        'X-tenant': 'desenvolvimento',
        'Authorization': 'Bearer 8|IwiQAfqgpKkGZ4p94DEgYu2DRaSCnkGQIfOYRroC'
    }
}