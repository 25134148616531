import '../../App';
import logoSigesis from '../../assets/images/sigesis.png';
import styleFooter from './Footer.module.css'

function Footer () {

    const date = new Date();

    return (
        <>
           
            <div className={styleFooter.footer} >
                <div className='row'>
                    <p>
                        <img src={ logoSigesis } alt='Logo Sigesis' width={25}/>
                        &nbsp;&bull;&nbsp;
                        { date.getFullYear() }
                        &nbsp;&bull;
                        Desenvolvido por <a className={ styleFooter.link } rel='noreferrer' href='http://www.telecom.inf.br' target="_blank" >Vale Telecom</a>
                    </p>
                </div>
            </div>
           
        </>
    );
}

export default Footer;