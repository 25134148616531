import axios from "axios";
import { useState, useEffect } from "react";
import styleOrders from './Orders.module.css';
import { Button } from 'reactstrap';
import { headersAxios, colorStatus } from '../../assets/scripts/functions';
import styled from 'styled-components';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, faUtensils, faBoxArchive, faSpinner } from '@fortawesome/free-solid-svg-icons'

const Order = styled.div`
	background-color: ${props => props.name === 'primary' ? '#0d6efd' : props.name === 'secondary' ? '#6c757d' : props.name === 'orange' ? '#fd7e14' : props.name === 'purple' ? '#6f42c1' : props.name === 'success' ? '#198754' : props.name === 'cyan' ? '#0dcaf0' : props.name === 'danger' ? '#dc3545' : props.name === 'dark' ? '#212529' : ''};
	color: ${props => props.name === 'primary' ? '#fff' : props.name === 'secondary' ? '#fff' : props.name === 'orange' ? '#fff' : props.name === 'purple' ? '#fff' : props.name === 'success' ? '#fff' : props.name === 'cyan' ? '#000' : props.name === 'danger' ? '#fff' : props.name === 'dark' ? '#fff' : ''};
    width: 150px;
    height: 150px;
    text-align: center;
    border: none;
    border-radius: 5px;
    margin-bottom: 20px;
`;

export default function OrdersDelivery () {

	const [dataOrders, setDataOrders] = useState([]);
	
	const getDataOrders = async () => {
		try {
			const response = await axios.get(`https://api.sigedelivery.com.br/orders?per_page=100&sort=id%2Cdesc`, {
				headers: headersAxios()
			});
			const data = response.data.data;
			setDataOrders(data);
		} catch (error) {
            console.error(error.config);
		}
	}

	useEffect(() => {
		getDataOrders();
	},[]);


    return (
        <>
            <div className={styleOrders.bodyOrders}>
                
                <div className="container">
                    
                    <div className={styleOrders.headerOrders}>
                        <div>
                            <h4>Pedidos de entrega</h4>
                        </div>

                        <div>
                            <Button color="secondary" type="button"><FontAwesomeIcon icon={faSpinner} /> Liberar selecionados</Button>
                        </div>
                    </div>

                    <hr />

                    <div className="row">
                        {
                            dataOrders.length === 0 ? (<tr>Carregando...</tr>) : (
                                dataOrders.map((data) => (    
                                    (data.id_status < 6 && data.entrega) ? 
                                    <div className="col-md-2">
                                        <Order name={colorStatus(data.id_status)}>
                                            <a href="/" className={styleOrders.cardsContents} id={data.id}>
                                                {   data.mesa ? 
                                                    <p>
                                                        <h2><FontAwesomeIcon icon={faUtensils} /></h2>
                                                        <br/> 
                                                        <strong>Comanda {data.id_mesa}</strong> 
                                                        <br/> 
                                                        <strong>Mesa {data.id_comanda}</strong> 
                                                    </p> 
                                                    : (data.balcao) ? 
                                                    <p>
                                                        <h2><FontAwesomeIcon icon={faBoxArchive} /></h2>
                                                        <br/> 
                                                        <strong>Balcão {data.id}</strong> 
                                                    </p> 
                                                    : (data.entrega) ?
                                                    <p>
                                                        <h2><FontAwesomeIcon icon={faPaperPlane} /></h2>
                                                        <br/> 
                                                        <strong>Entrega {data.id}</strong>
                                                    </p> 
                                                    : ''
                                                }
                                            </a>
                                        </Order>
                                    </div>
                                    : ''
                                ))
                            )
                        }
                    </div>

                </div>


            </div>
        </>
    );
}