import React from 'react'
import styleAsideItem from './AsideItens.module.css'

function AsideItens(props) {
  return (
    <>
      <div className={styleAsideItem.asideitem}>
        <span className={styleAsideItem.text}>{props.label}</span>
        <span className={props.className}>{props.data}</span>
      </div>
    </>
  )
}

export default AsideItens;