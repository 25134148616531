import LogoMenu from '../../assets/images/logo-menu.png';
import styleHeader from './Header.module.css';
import NavbarMenu from "../NavbarMenuComponents/NavbarMenu";
import { Link } from 'react-router-dom';

function Header() {
	return(
		<>
			<div className={styleHeader.bckColor}>
				<div className='container'>
					<div className='row'>
						<div className='col-md-3'>
							<figure>
								<img src={LogoMenu} alt='Logo Menu Sigesis' width={180}/>
							</figure>
						</div>

						<div className='col-md-9'>
							<div className='row'>
								<div className="col-md-3 text-center">
									{/* <Link to='/venda'>  */}
										<i className="bi bi-send-fill"></i> <br></br>
										Abrir Entrega (F8)
										<a href="pedido-entrega" className=''>TESTE</a>
									{/* </Link> */}
								</div>
								<div className="col-md-3 text-center">
									{/* <Link> */}
										<i className="bi bi-cart"></i><br></br>
										Abrir Balcão (F9)
										<a href="pedido-balcao" className=''>TESTE</a>
									{/* </Link> */}
								</div>
								<div className="col-md-3 text-center">
									{/* <Link to='/pedido-comanda'> */}
										<i className="bi bi-qr-code"></i> <br></br>
										Abrir Comanda (F10)
										<a href="pedido-comanda" className=''>TESTE</a>
									{/* </Link> */}
								</div>
							</div>
						</div>
					</div>
				</div>
				<NavbarMenu />
			</div>
		</>
	)
}

export default Header