import { useEffect, useState } from 'react'
import styleMain from './Main.module.css'
import axios from 'axios';
import { Button, Table } from 'reactstrap';
import { formatNumber, formatDate, colorStatus, headersAxios } from '../../../assets/scripts/functions';
import ModalDataCustomer from './ModalDataCustomer/ModalDataCustomer';
import ModalPayment from './ModalPayment/ModalPayment';
import styled from 'styled-components';

const Badge = styled.p`
	background-color: ${props => props.name === 'primary' ? '#0d6efd' : props.name === 'secondary' ? '#6c757d' : props.name === 'orange' ? '#fd7e14' : props.name === 'purple' ? '#6f42c1' : props.name === 'success' ? '#198754' : props.name === 'cyan' ? '#0dcaf0' : props.name === 'danger' ? '#dc3545' : props.name === 'dark' ? '#212529' : ''};
	border-radius: 5px;
	text-align: center;
	color: ${props => props.name === 'primary' ? '#fff' : props.name === 'secondary' ? '#fff' : props.name === 'orange' ? '#fff' : props.name === 'purple' ? '#fff' : props.name === 'success' ? '#fff' : props.name === 'cyan' ? '#000' : props.name === 'danger' ? '#fff' : props.name === 'dark' ? '#fff' : ''};
	padding: 2px;
	font-size: 12px;
	font-weight: bold;
`;

export default function Main() {

	const id_pedido = 1475; //Tem que vir na requisição
	const [dataTableItems, setDataTableItems] = useState([]);
	
	const getDataItems = async () => {
		try {
			const response = await axios.get(`https://api.sigedelivery.com.br/orders/${id_pedido}/items?with=status%2Ccatalog_item%2Coptions`, {
				headers: headersAxios()
			});
			const data = response.data.data;
			setDataTableItems(data);
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		getDataItems();
	},[]);

	//Modais
	const [modalPayment, setModalPayment] = useState(false);
	const togglePayment = () => setModalPayment(!modalPayment);
	
	const [modalDataCustomer, setModalDataCustomer] = useState(false);
	const toggleDataCustomer = () => setModalDataCustomer(!modalDataCustomer);
	
	const [modalMenu, setModalMenu] = useState(false);
	const toggleMenu = () => setModalMenu(!modalMenu);
	
	return (
		<div className={styleMain.main}>
			<div className={styleMain.topmain}>
				<Button color='primary' onClick={togglePayment}><i className="bi bi-currency-dollar"></i>&nbsp;Pagamento</Button>
				&nbsp;&nbsp;&nbsp;&nbsp;
				<Button color='danger' onClick={toggleDataCustomer}><i className="bi bi-card-list"></i>&nbsp;Dados do pedido</Button>
			</div>

			{/* Table Items */}
			<div className={styleMain.table}>
				<Table hover>
					<thead>
						<tr>
							<th> # </th>
							<th> Tempo </th>
							<th> Item </th>
							<th> Quant. </th>
							<th> Valor </th>
							<th> Opção </th>
							<th> Total </th>
							<th> Observação </th>
							<th> Data </th>
							<th> Status </th>
							<th> Ação </th>
						</tr>
					</thead>
					<tbody>
						
						{
							
							dataTableItems.length === 0 ? (<tr>Carregando...</tr>) : (
								dataTableItems.map((data) => (
									
									<tr>
										<th scope="row">{ data.id }</th>
										<td> 00:00:00 </td>
										<td> { data.catalog_item.nome } </td>
										<td> { (data.quantidade) ? parseFloat(data.quantidade) : 0 } </td>
										<td> { (data.valor) ? formatNumber(data.valor) : formatNumber(0) } </td>
										<td> 
											{ 
												(data.options) ? data.options.map((v) => (formatNumber(v.valor_total))) : formatNumber(0)
											} 
										</td>
										<td> { (data.valor_total) ? formatNumber(data.valor_total) : formatNumber(0) } </td>
										<td> { (data.observacao) ? data.observacao : '' } </td>
										<td> { (data.data_aberto) ? formatDate(data.data_aberto) : "-" } </td>
										<td> 
											<Badge name={colorStatus(data.status.id)}>{data.status.status}{(colorStatus(data.status.id_status))}</Badge> 
										</td> 
										<td> <Button className='btn btn-danger' id={data.id}><i className="bi bi-x"></i></Button> </td>
									</tr>
								))
							)
	

						}
					
					
					
					</tbody>
				</Table>
			</div>

			<ModalPayment toggle={togglePayment} isOpen={modalPayment}></ModalPayment>
			<ModalDataCustomer toggle={toggleDataCustomer} isOpen={modalDataCustomer}></ModalDataCustomer>
			{/* <ModalMenu toggle={toggleMenu} isOpen={modalMenu}></ModalMenu> */}

		</div>
	)
}

