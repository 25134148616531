import { headersAxios } from '../../../../assets/scripts/functions';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, Form, FormGroup, Label } from "reactstrap";
import { useEffect, useState } from "react";
import axios from "axios";

export default function ModalDataCustomer (props) {

    const id_pedido = 1475; //Tem que vir na requisição
	const [dataTableItems, setDataTableItems] = useState([]);
	
	const getDataItems = async () => {
		try {
			const response = await axios.get(`https://api.sigedelivery.com.br/orders/${id_pedido}/items?with=status%2Ccatalog_item%2Coptions`, {
				headers: headersAxios()
			});
			const data = response.data.data;
			setDataTableItems(data);
		} catch (err) {
			console.log(err)
		}
	}

	useEffect(() => {
		getDataItems();
	},[]);


    return (
        <>
            <Modal isOpen={props.modal} toggle={props.toggle} {...props} size='lg'>
                <ModalHeader toggle={props.toggle}><i className="bi bi-card-list"></i> Dados do pedido e do cliente</ModalHeader>
                    <ModalBody>
                        <p>Preencha estes campos com os dados para entrega do pedido ou sobre o cliente.</p>
                        <br/>
                        <Form>
                            <div className="col-md-12">
                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="row">
                                            <FormGroup>
                                                <Label>Buscar cliente</Label>
                                                <Input name="cliente" placeholder="" bsSize="sm"></Input>
                                            </FormGroup>
                                        </div>
                                        <div className="row">
                                            <FormGroup>
                                                <Label>Nome</Label>
                                                <Input name="nome" id="nome" placeholder="" bsSize="sm"></Input>
                                            </FormGroup>
                                        </div>
                                        <div className="row">
                                            <FormGroup>
                                                <Label>Telefone</Label>
                                                <Input name="telefone" id="telefone" placeholder="" bsSize="sm"></Input>
                                            </FormGroup>
                                        </div>
                                        <div className="row">
                                            <FormGroup>
                                                <Label>Endereço</Label>
                                                <Input name="endereco" placeholder="" bsSize="sm"></Input>
                                            </FormGroup>
                                        </div>
                                        <div className="row">
                                            <FormGroup>
                                                <Label>Número</Label>
                                                <Input name="numero" placeholder="" bsSize="sm"></Input>
                                            </FormGroup>
                                        </div>
                                        <div className="row">
                                            <FormGroup>
                                                <Label>Atendente</Label>
                                                <Input type="select" name="id_local" placeholder="Selecione o atendente" bsSize="sm">
                                                    <option>
                                                        1
                                                    </option>
                                                
                                                </Input>
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="row">
                                            <FormGroup>
                                                <Label>Complemento</Label>
                                                <Input name="complemento" placeholder="" bsSize="sm"></Input>
                                            </FormGroup>
                                        </div>
                                        <div className="row">
                                            <FormGroup>
                                                <Label>Referência</Label>
                                                <Input name="referencia" placeholder="" bsSize="sm"></Input>
                                            </FormGroup>
                                        </div>
                                        <div className="row">
                                            <FormGroup>
                                                <Label>Bairro</Label>
                                                <Input name="bairro" placeholder="" bsSize="sm"></Input>
                                            </FormGroup>
                                        </div>
                                        <div className="row">
                                            <FormGroup>
                                                <Label>Local de Entrega</Label>
                                                <Input type="select" name="id_local" placeholder="" bsSize="sm">
                                                <option>
                                                    1
                                                </option>
                                                
                                                </Input>
                                            </FormGroup>
                                        </div>
                                        <div className="row">
                                            <FormGroup>
                                                <Label>Observação</Label>
                                                <Input name="observacao" placeholder="" bsSize="sm"></Input>
                                            </FormGroup>
                                        </div>
                                        <div className="row">
                                            <FormGroup>
                                                <Label>CPF/CNPJ</Label>
                                                <Input name="cpf_cnpj" placeholder="" bsSize="sm"></Input>
                                            </FormGroup>
                                        </div>
                                        
                                    </div>
                                </div>
                            </div>
                        </Form>

                    </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={props.toggle}>
                        Salvar
                    </Button>{' '}
                    <Button color="secondary" onClick={props.toggle}>
                        Sair
                    </Button>
                </ModalFooter>
            </Modal>
        </>
    );
}