import React, { useEffect, useState } from 'react';
import styleAside from './Aside.module.css';
import AsideItens from './AsideItens';
import axios from 'axios';
import { formatNumber, formatDate, colorStatus, headersAxios } from '../../../assets/scripts/functions';

import styled from 'styled-components';

const Badge = styled.p`
	background-color: ${props => props.name === 'primary' ? '#0d6efd' : props.name === 'secondary' ? '#6c757d' : props.name === 'orange' ? '#fd7e14' : props.name === 'purple' ? '#6f42c1' : props.name === 'success' ? '#198754' : props.name === 'cyan' ? '#0dcaf0' : props.name === 'danger' ? '#dc3545' : props.name === 'dark' ? '#212529' : ''};
	border-radius: 5px;
	text-align: center;
	color: ${props => props.name === 'primary' ? '#fff' : props.name === 'secondary' ? '#fff' : props.name === 'orange' ? '#fff' : props.name === 'purple' ? '#fff' : props.name === 'success' ? '#fff' : props.name === 'cyan' ? '#000' : props.name === 'danger' ? '#fff' : props.name === 'dark' ? '#fff' : ''};
	padding: 3px;
	margin: 0;
	font-size: 12px;
	font-weight: bold;
`;

function Aside(props) {

  	const id_pedido = 1475 ;
	const [dataOrder, setDataOrder] = useState([]);

    const getDataOrders = async () => {
		try {
			const response = await axios.get(`https://api.sigedelivery.com.br/orders/${id_pedido}?with=status%2Cpayments`, {
				headers:  headersAxios()
			})
			const data = response.data;
			const array = [];
			array.push(data);
			setDataOrder(array);
		} catch (error) {
			console.log(error)            
		}
  	}

	useEffect(() => {
		getDataOrders();
	}, [])

  return (
    <>
		{
			dataOrder.length === 0 ? (<div>Carregando...</div>) : (
				dataOrder.map((order) => (
					<div className={styleAside.aside}>
						<AsideItens className={colorStatus(order.status.id)} label="STATUS DO PEDIDO" data={<Badge name={colorStatus(order.status.id)}>{order.status.status}</Badge>} />
						{ 	order.mesa ? 
							<AsideItens className='badge bg-info' label="COMANDA" data={order.id_mesa} />
							: ''
						}
						
						{ 	order.mesa ? 
								<AsideItens className='badge bg-info' label="MESA" data={order.id_comanda} />
							: ''
						}
						
						<AsideItens className='badge bg-dark' label="NÚMERO DO PEDIDO" data={order.id} />
						{ 	order.balcao ? 
							<AsideItens className='badge bg-dark' label="SENHA" data={order.senha} />
							: ''
						}
						
						<AsideItens className='badge bg-dark' label="DATA DO PEDIDO" data={formatDate(order.data_aberta)} />
						<AsideItens className='badge bg-dark' label="VALOR DO PEDIDO" data={formatNumber(order.valor_pedido)} />
						
						{	order.taxa_garcom ? 
							<AsideItens className='badge bg-dark' label="GORJETA" data={formatNumber(order.valor_taxa_garcom)} />
							: ''
						}
						
						<AsideItens className='badge bg-primary' label="VALOR TOTAL" data={formatNumber(order.valor_total)} />

						{
							order.valor_troco && order.entrega ? 
							<AsideItens className='badge bg-dark' label="TROCO SOLICITADO" data={formatNumber(order.valor_total)} />
							: ''
						}

						<AsideItens className='badge bg-success' label="VALOR PAGO" data={formatNumber(order.valor_pago)} />
						<AsideItens className='badge bg-danger' label="VALOR A COBRAR" data={formatNumber(order.valor_total - order.valor_pago)} />
      				</div>
				))
			)
		}
    </>
  );
}

export default Aside;