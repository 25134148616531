import Header from './pages/HeaderComponents/Header';
import MainScreen from './pages/MainScreenComponents/MainScreen';
import Footer from './pages/FooterComponents/Footer';
// import Routes from './routes/Routes';
import {BrowserRouter, Route, Routes } from 'react-router-dom';

function App () {
	return (
		<div>
			<Header />
			<MainScreen />
			{/* 
			<BrowserRouter>
				<Routes>
				</Routes>
				</BrowserRouter> 
			*/}
			{/* <Route path="/pedido-entrega" element={<MainScreen/>} /> */}
			{/* </Route> */}
			<Footer />

		</div>
	);
}

export default App;